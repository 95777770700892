import React from "react";

const PlanosRevenda = () => {
  const whatsappBaseURL = "https://wa.me/551231990322";

  const generateMessage = (planName, price) =>
    encodeURIComponent(
      `Olá, quero ser um revendedor de sucesso e estou interessado no plano "${planName}" com o valor de ${price}.`
    );

  return (
    <section id="planos" className="py-5 bg-light text-center">
      <div className="container">
        <h2 className="mb-4 fw-bold">Tudo isso para você ter sucesso na revenda!</h2>
        <div className="row">
          {/* Card 1 */}
          <div className="col-md-4 mb-4">
            <div className="card shadow border-0 h-100">
              <div className="card-body">
                <h5 className="text-success fw-bold">1.000 Créditos Revenda</h5>
                <p className="text-muted">Painel WPlay</p>
                <h3 className="fw-bold text-success">R$6<span style={{ fontSize: "1rem" }}>,00</span></h3>
                <p className="text-muted">Preço Unitário</p>
                <hr />
                <p className="fw-bold">Total: R$6.000,00</p>
                <a
                  href={`${whatsappBaseURL}?text=${generateMessage(
                    "1.000 Créditos Revenda",
                    "R$6.000,00"
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-success btn-lg mt-3 w-100"
                >
                  Quero Revender
                </a>
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-md-4 mb-4">
            <div className="card shadow border-0 h-100">
              <div className="card-body">
                <h5 className="text-success fw-bold">100 Créditos Revenda</h5>
                <p className="text-muted">Painel WPlay</p>
                <h3 className="fw-bold text-success">R$8<span style={{ fontSize: "1rem" }}>,00</span></h3>
                <p className="text-muted">Preço Unitário</p>
                <hr />
                <p className="fw-bold">Total: R$800,00</p>
                <a
                  href={`${whatsappBaseURL}?text=${generateMessage(
                    "100 Créditos Revenda",
                    "R$800,00"
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-success btn-lg mt-3 w-100"
                >
                  Quero Revender
                </a>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="col-md-4 mb-4">
            <div className="card shadow border-0 h-100">
              <div className="card-body">
                <h5 className="text-success fw-bold">10 Créditos Revenda</h5>
                <p className="text-muted">Painel WPlay</p>
                <h3 className="fw-bold text-success">R$11<span style={{ fontSize: "1rem" }}>,00</span></h3>
                <p className="text-muted">Preço Unitário</p>
                <hr />
                <p className="fw-bold">Total: R$110,00</p>
                <a
                  href={`${whatsappBaseURL}?text=${generateMessage(
                    "10 Créditos Revenda",
                    "R$110,00"
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-success btn-lg mt-3 w-100"
                >
                  Quero Revender
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlanosRevenda;

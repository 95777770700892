import React from "react";
import { FaCheck } from "react-icons/fa";

const Revenda = () => {
  const whatsappBaseURL = "https://wa.me/551231990322";
  const message = encodeURIComponent(
    "Quero ser uma revenda Wapptv, me dê mais informações por favor."
  );

  return (
    <section id="revenda" className="py-5 bg-light">
      <div className="container">
        <div className="row align-items-center">
          {/* Texto à esquerda */}
          <div className="col-md-6 mb-4 mb-md-0">
            <h2 className="fw-bold text-success">Quero Revender. Como Funciona?</h2>
            <p className="text-muted fs-5">
              Dê o primeiro passo para construir um negócio lucrativo e escalável com a revenda!
            </p>
            <ul className="list-unstyled">
              <li className="mb-3">
                <FaCheck className="text-success me-2" aria-hidden="true" />
                <span>Escolha quantos créditos e entre em contato no WhatsApp!</span>
              </li>
              <li className="mb-3">
                <FaCheck className="text-success me-2" aria-hidden="true" />
                <span>
                  Investimento inicial a partir de <strong>R$110,00</strong>
                </span>
              </li>
              <li className="mb-3">
                <FaCheck className="text-success me-2" aria-hidden="true" />
                <span>
                  Lucros aproximados à <strong>150%</strong> sobre o seu investimento inicial.
                </span>
              </li>
              <li className="mb-3">
                <FaCheck className="text-success me-2" aria-hidden="true" />
                <span>
                  Fornecemos treinamento e suporte sem nenhum custo para que tenha sucesso em suas vendas.
                </span>
              </li>
            </ul>
            <a
              href={`${whatsappBaseURL}?text=${message}`}
              className="btn btn-success btn-lg mt-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Quero ser revenda
            </a>
          </div>

          {/* Imagem à direita */}
          <div className="col-md-6">
            <img
              src="https://wplaystreaming.top/wp-content/uploads/2024/07/photo_2024-07-13_15-52-08.jpg"
              alt="Seja nosso revendedor"
              className="img-fluid rounded shadow"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Revenda;

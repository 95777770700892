import React from "react";
import { FaTv, FaStream, FaBolt } from "react-icons/fa";

const Features = () => (
  <section id="features" className="py-5 bg-light text-center">
    <div className="container">
      <h2 className="mb-5 fw-bold text-success">Recursos Incríveis</h2>
      <div className="row gy-4">
        {/* Compatível com dispositivos */}
        <div className="col-md-4">
          <div className="card border-0 shadow h-100">
            <div className="card-body">
              <div
                className="icon mb-3"
                style={{ fontSize: "3rem", color: "#28a745" }} // Cor verde
              >
                <FaTv />
              </div>
              <h4 className="card-title fw-bold">Compatível com todos os dispositivos</h4>
              <p className="card-text text-muted">
                Assista em Smart TVs, celulares, computadores e mais.
              </p>
            </div>
          </div>
        </div>

        {/* Mais de 3.000 canais */}
        <div className="col-md-4">
          <div className="card border-0 shadow h-100">
            <div className="card-body">
              <div
                className="icon mb-3"
                style={{ fontSize: "3rem", color: "#28a745" }} // Cor verde
              >
                <FaStream />
              </div>
              <h4 className="card-title fw-bold">Mais de 3.000 canais</h4>
              <p className="card-text text-muted">
                Explore uma vasta seleção de canais nacionais e internacionais.
              </p>
            </div>
          </div>
        </div>

        {/* Sem travamentos */}
        <div className="col-md-4">
          <div className="card border-0 shadow h-100">
            <div className="card-body">
              <div
                className="icon mb-3"
                style={{ fontSize: "3rem", color: "#28a745" }} // Cor verde
              >
                <FaBolt />
              </div>
              <h4 className="card-title fw-bold">Sem travamentos</h4>
              <p className="card-text text-muted">
                Streaming otimizado para conexões de baixa velocidade.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Features;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Importação do React Router
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./components/Header";
import Hero from "./components/Hero";
import Features from "./components/Features";
import Planos from "./components/Planos";
import PlanosRevemda from "./components/PlanosRevemda";
import Revenda from "./components/Revenda";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import { SpeedInsights } from "@vercel/speed-insights/react";
import ConsentBanner from "./components/ConsentBanner";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Contato from "./pages/Contato"; // Importa a página de Contato

function App() {
  return (
    <Router>
      {/* Cabeçalho sempre visível */}
      <Header />

      {/* Rotas Definidas */}
      <Routes>
        {/* Página Inicial */}
        <Route
          path="/"
          element={
            <>
              <SpeedInsights />
              <Hero />
              <Features />
              <ConsentBanner />
              <Planos />
              <Revenda />
              <PlanosRevemda />
              <FAQ />
            </>
          }
        />

        {/* Página de Política de Privacidade */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Página de Contato */}
        <Route path="/contato" element={<Contato />} />
      </Routes>

      {/* Rodapé global (exibido apenas uma vez) */}
      <Footer />
    </Router>
  );
}

export default App;

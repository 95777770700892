import React, { useState, useEffect } from "react";

const ConsentBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("userConsent");
    if (!consent) {
      setIsVisible(true);
      initializeGtagWithDefaultConsent();
    }
  }, []);

  // Função para inicializar o gtag com consentimento padrão negado
  const initializeGtagWithDefaultConsent = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag("js", new Date());

    gtag("config", "G-7C8SNJ3FVL");

    gtag("consent", "default", {
      ad_storage: "denied",
      analytics_storage: "denied",
      ad_user_data: "denied",
      ad_personalization: "denied",
    });
  };

  // Quando o usuário aceita o consentimento
  const handleAccept = () => {
    localStorage.setItem("userConsent", "granted");

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag("consent", "update", {
      ad_storage: "granted",
      analytics_storage: "granted",
      ad_user_data: "granted",
      ad_personalization: "granted",
    });

    setIsVisible(false);
  };

  // Quando o usuário recusa o consentimento
  const handleDeny = () => {
    localStorage.setItem("userConsent", "denied");

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag("consent", "update", {
      ad_storage: "denied",
      analytics_storage: "denied",
      ad_user_data: "denied",
      ad_personalization: "denied",
    });

    setIsVisible(false);
  };

  // Renderizar o banner de consentimento
  return (
    isVisible && (
      <div style={styles.banner}>
        <p style={styles.text}>
          Utilizamos cookies para melhorar sua experiência. Aceite para continuar.
        </p>
        <button onClick={handleAccept} style={styles.buttonAccept}>
          Aceitar
        </button>
        <button onClick={handleDeny} style={styles.buttonDeny}>
          Negar
        </button>
      </div>
    )
  );
};

const styles = {
  banner: {
    position: "fixed",
    bottom: "20px",
    left: "20px", // Alinhado à esquerda
    backgroundColor: "#333",
    color: "#fff",
    padding: "15px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    zIndex: 1000,
    maxWidth: "300px", // Limita a largura do banner
  },
  text: {
    margin: 0,
    flex: 1,
    fontSize: "14px",
  },
  buttonAccept: {
    backgroundColor: "#0fd75d",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "8px 12px",
    cursor: "pointer",
    marginRight: "10px",
  },
  buttonDeny: {
    backgroundColor: "#ff4d4d",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "8px 12px",
    cursor: "pointer",
  },
};

export default ConsentBanner;

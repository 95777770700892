import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQ = () => (
  <section id="faq" className="py-5 bg-light">
    <div className="container">
      <h2 className="fw-bold text-success mb-4 text-center">FAQ - Perguntas e Respostas</h2>
      <Accordion>
        {/* Pergunta 1 */}
        <Accordion.Item eventKey="0">
          <Accordion.Header>Posso testar antes de comprar?</Accordion.Header>
          <Accordion.Body>
            Sim, fornecemos um teste de 4 horas para você conhecer. Temos mais de 80.000 conteúdos disponíveis!
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 2 */}
        <Accordion.Item eventKey="1">
          <Accordion.Header>Não tenho muito conhecimento nessa área, como posso iniciar?</Accordion.Header>
          <Accordion.Body>
            Não se preocupe! Você pode usar em casa ou começar a revender. Oferecemos um grupo no WhatsApp para suporte e temos uma equipe pronta para ajudar sempre que precisar.
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 3 */}
        <Accordion.Item eventKey="2">
          <Accordion.Header>Quanto custa o painel?</Accordion.Header>
          <Accordion.Body>
            O painel não tem custo, você apenas compra os créditos conforme necessário. (Verifique a tabela de preços).
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 4 */}
        <Accordion.Item eventKey="3">
          <Accordion.Header>Qual a compra mínima de créditos?</Accordion.Header>
          <Accordion.Body>
            Para clientes finais, você pode adquirir um dos planos mensais. Para revendedores, o pacote mínimo é de 10 créditos.
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 5 */}
        <Accordion.Item eventKey="4">
          <Accordion.Header>Posso gerar testes? E tem custos?</Accordion.Header>
          <Accordion.Body>
            O teste é a melhor forma de atrair clientes. Revendedores podem gerar testes ilimitados para seus clientes sem custos. Cada teste tem duração de 4 horas.
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 6 */}
        <Accordion.Item eventKey="5">
          <Accordion.Header>Posso vender a qual valor para meus clientes?</Accordion.Header>
          <Accordion.Body>
            De acordo com nossas regras, você pode vender para seus clientes qualquer preço acima de R$30. Respeite as normas do servidor para não ter seu painel bloqueado. Para saber mais, entre em contato com nosso suporte.
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 7 */}
        <Accordion.Item eventKey="6">
          <Accordion.Header>Por que usar P2P e não IP-TV?</Accordion.Header>
          <Accordion.Body>
            <strong>P2P:</strong> Pode ser utilizado com nosso aplicativo no sistema Android, proporcionando melhor tecnologia de streaming para assistir conteúdos ao vivo sem travamentos, mesmo com internet de baixa qualidade. Funciona com internet a partir de 5 Mbps e não está sujeito a bloqueios das operadoras de internet (traffic shaping).
            <br /><br />
            <strong>IP-TV:</strong> É a tecnologia mais tradicional, compatível com aplicativos de IP-TV em Smart TVs, celulares e tablets. Funciona até pelo navegador sem aplicativos. No entanto, requer internet de alta qualidade e pode sofrer bloqueios por operadoras de internet.
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 8 */}
        <Accordion.Item eventKey="7">
          <Accordion.Header>É fornecido suporte após a compra?</Accordion.Header>
          <Accordion.Body>
            Sim, damos todo o suporte para você, seja revendedor ou cliente final. Temos um grupo no WhatsApp para auxiliar sempre que necessário.
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 9 */}
        <Accordion.Item eventKey="8">
          <Accordion.Header>Quais as formas de pagamento?</Accordion.Header>
          <Accordion.Body>
            Aceitamos apenas Pix, Cartão e Boleto.
          </Accordion.Body>
        </Accordion.Item>

        {/* Pergunta 10 */}
        <Accordion.Item eventKey="9">
          <Accordion.Header>O que é traffic shaping?</Accordion.Header>
          <Accordion.Body>
            É uma prática ilegal, onde as operadoras de internet reduzem a velocidade e qualidade da conexão para serviços de streaming. Nosso sistema P2P é totalmente livre desses problemas, garantindo uma experiência de streaming de alta qualidade.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  </section>
);

export default FAQ;

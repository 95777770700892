import React from "react";

const Contato = () => {
  return (
    <section id="contato" className="py-5 bg-light">
      <div className="container">
        <div className="row">
          {/* Título da página */}
          <div className="col-12 text-center mb-5" style={{ paddingTop: '100px' }}>
            <h1 className="text-success fw-bold">Entre em Contato</h1>
            <p className="text-muted fs-5">
              Preencha o formulário ou entre em contato diretamente com a gente pelos dados abaixo.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-4">
            <h3 className="fw-bold text-success">Informações de Contato</h3>
            <ul className="list-unstyled">
              <li className="mb-2"><strong>Endereço:</strong>Brasil - BR</li>
              <li className="mb-2"><strong>Telefone:</strong> <a href="tel:+551231990322">+55 12 3199-0322</a></li>
              <li className="mb-2"><strong>Email:</strong> <a href="mailto:contato@waapptv.online">contato@waapptv.online </a></li>
            </ul>
          </div>
          <div className="col-md-6">
            <h3 className="fw-bold text-success">Formulário de Contato</h3>
            <form>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Nome</label>
                <input type="text" className="form-control" id="name" placeholder="Seu nome" required />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control" id="email" placeholder="seuemail@exemplo.com" required />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">Mensagem</label>
                <textarea className="form-control" id="message" rows="4" placeholder="Digite sua mensagem" required></textarea>
              </div>
              <button type="submit" className="btn btn-success">Enviar</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contato;

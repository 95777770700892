import React, { useState, useEffect } from "react";
import styled from "styled-components";

const HeroSection = styled.section`
  height: 100vh;
  background: ${(props) =>
    `url('${props.backgroundImage}') no-repeat center center/cover`};
  position: relative;
  color: #fff;

  /* Sobreposição escura */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); /* Ajuste a opacidade conforme necessário */
    z-index: 1;
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 2;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;

  span {
    color: #28a745; /* Cor verde */
  }

  @media (min-width: 768px) {
    font-size: 3.5rem; /* Tamanho maior em telas médias */
  }
`;

const Subtitle = styled.p`
  font-size: 1rem;
  margin-bottom: 2rem;
  line-height: 1.6;

  @media (min-width: 768px) {
    font-size: 1.25rem; /* Tamanho maior em telas médias */
  }
`;

const Button = styled.a`
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease;
  margin-right: 1rem;

  &.primary {
    background: #28a745;
    color: #fff;

    &:hover {
      background: #218838;
    }
  }

  &.secondary {
    border: 2px solid #28a745;
    color: #28a745;

    &:hover {
      background: #28a745;
      color: #fff;
    }
  }
`;

const Hero = () => {
  const images = [
    "/assets/img/hero.webp",
    "/assets/img/home-hero-1.png",
    "/assets/img/home-hero-2.png",
    "/assets/img/home-hero-3.png",
    "/assets/img/home-hero-4.png",
  ];

  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    const randomImage = images[Math.floor(Math.random() * images.length)];
    setBackgroundImage(randomImage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const whatsappBaseURL = "https://wa.me/551231990322";
  const testMessage = encodeURIComponent(
    "Olá, gostaria de solicitar um teste grátis de 1 hora. Poderiam me ajudar?"
  );

  return (
    <HeroSection backgroundImage={backgroundImage}>
      <div className="container h-100">
        <div className="row align-items-center h-100">
          {/* Conteúdo da Esquerda */}
          <div className="col-12 col-md-6">
            <HeroContent>
              <Title>
                Transforme sua experiência em <span> entretenimento</span>
              </Title>
              <Subtitle>
                Filmes, séries e TV ao vivo sem limites! Assista onde quiser,
                quando quiser, com a qualidade que você merece. Seja no conforto
                de casa ou em qualquer lugar, você tem acesso a milhares de
                conteúdos incríveis.
              </Subtitle>

              <div>
                <Button
                  href={`${whatsappBaseURL}?text=${testMessage}`}
                  className="primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Peça seu Teste Grátis
                </Button>
              </div>
            </HeroContent>
          </div>

          {/* Espaço para Imagem ou Efeito no Futuro */}
          <div className="col-12 col-md-6 d-none d-md-block">
            {/* Você pode adicionar algo aqui, como um banner ou vídeo. */}
          </div>
        </div>
      </div>
    </HeroSection>
  );
};

export default Hero;
